import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import { API_LOCATION } from "../../config"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    page_title: '',
    status: '',
    token: localStorage.getItem('token') || '',
    logged: localStorage.getItem('token') || false,
    user: {},
    error: false,
    errorMessage: '',
    msisdn:null,
    fname: '',
    lname: '',
    prof_set: '',
    points: null,
    avatar: '',
    sales_point:'',
    content: '',
    color: '',
    contract: null,
    bday:'',

    snack: '',

  },
  mutations: {

    setSnack(state, snack) {
      state.snack = snack
    },

    page_title_set(state, page_title) {
      state.page_title = page_title
    },

    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
      state.logged = true
    },
    auth_error(state) {
      state.status = 'error'
      state.logged = false
      state.token = ''
      state.errorMessage = 'Проверьте введённые данные'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.logged = false
    },

    pset(state, data) {
      state.prof_set = data.prof_set
      state.msisdn = data.msisdn
      state.fname = data.fname
      state.lname = data.lname
      state.points = data.points
      state.avatar = data.avatar
      state.sales_point = data.sales_point
      state.contract = data.contract
      state.bday = data.bday
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: API_LOCATION, data: user, method: 'POST' })
          .then(resp => {
            if (resp.data.token !== '') {
              const token = resp.data.token
              const user = resp.data.user
              localStorage.setItem('token', token)
              axios.defaults.headers.common['Authorization'] = token
              commit('auth_success', token, user)
              
            }
            else {
              commit('auth_error')
              localStorage.removeItem('token')

              reject('error') 
            }

            console.log(resp.data)
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')

            reject(err)
          })
      })
    },
    profileset({ commit }) {
      return new Promise((resolve, reject) => {
      axios.post(API_LOCATION, { data: 'component_data', action: 'load_profile' }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
        .then(resp => {
          
          if(resp.data.prof_set=='11')
          {
            reject(resp);
          }
          const msisdn = resp.data.msisdn
          const fname = resp.data.fname
          const lname = resp.data.lname
          const points = resp.data.points
          const avatar = resp.data.avatar
          const prof_set= resp.data.prof_set
          const sales_point=resp.data.sales_point
          const contract = resp.data.contract
          this.username = resp.data.username
          this.date = resp.data.bday
          const bday = resp.data.bday
          commit('pset', { msisdn,fname, lname, points, avatar, prof_set,sales_point, contract,bday })
          console.log(resp.data)
          console.log('PROFILESET:'+prof_set)
          resolve(resp)
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    page_title_set({ commit }, page_title) {
      commit('page_title_set', page_title)

    }

  },
  getters: {
    isLoggedIn: state => !!state.token,
    page_title: state => state.page_title,
    //isLoggedIn: state => state.logged,
    authStatus: state => state.status,
    errorMessage: state => state.errorMessage,
    msisdn: state => state.msisdn,
    fname: state => state.fname,
    lname: state => state.lname,
    points: state => state.points,
    bday: state => state.bday,
    avatar: state => state.avatar,
    prof_set: state => state.prof_set,
    sales_point: state => state.sales_point,
    contract: state => state.contract,
  }
}) 